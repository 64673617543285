import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Home';
import Navbar from './Navbar';
import Footer from './Footer';
import NewSeason from './NewSeason';
import Team from './Team';
import { useEffect } from 'react';

function App() {
  useEffect(()=>{
    window.location.replace('https://rsvp.withgoogle.com/events/cv');
  }, []);

  return (
    <>
    </>
  );
}

export default App;
